export function getProvinces(language: "en" | "fr") {
  const provincesObject: Provinces = {
    AB: {
      en: "ALBERTA",
      fr: "ALBERTA",
    },
    BC: {
      en: "BRITISH COLUMBIA",
      fr: "COLOMBIE-BRITANNIQUE",
    },
    MB: {
      en: "MANITOBA",
      fr: "MANITOBA",
    },
    NB: {
      en: "NEW BRUNSWICK",
      fr: "NOUVEAU-BRUNSWICK",
    },
    NL: {
      en: "NEWFOUNDLAND & LABRADOR",
      fr: "TERRE-NEUVE-ET-LABRADOR",
    },
    NS: {
      en: "NOVA SCOTIA",
      fr: "NOUVELLE-ÉCOSSE",
    },
    NT: {
      en: "NORTHWEST TERRITORIES",
      fr: "(TERRITOIRES DU) NORD-OUEST",
    },
    NU: {
      en: "NUNAVUT",
      fr: "NUNAVUT",
    },
    ON: {
      en: "ONTARIO",
      fr: "ONTARIO",
    },
    PE: {
      en: "PRINCE EDWARD ISLAND",
      fr: `L'îLE DU PRINCE-ÉDOUARD`,
    },
    QC: {
      en: "QUEBEC",
      fr: "QUÉBEC",
    },
    SK: {
      en: "SASKATCHEWAN",
      fr: "SASKATCHEWAN",
    },
    YT: {
      en: "YUKON",
      fr: "(TERRITOIRE DU) YUKON",
    },
  };

  const provinces: any = [];
  Object.keys(provincesObject).map((key: string) => {
    return provinces.push([key, provincesObject[key][language]]);
  });

  return provinces;
}

interface Provinces {
  [key: string]: ProvinceLang;
}

interface ProvinceLang {
  en: string;
  fr: string;
}
